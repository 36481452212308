<template>
  <div class="contentmain">
    <el-card class="box-card">
      <div class="touxiangup">
        <img
          class="touxianguppic"
          :src="'https://freemen.work/user/file/view/' + infoForm.avatar"
          v-if="infoForm.avatar"
          @click="addtouxiang()"
        />
        <div class="touxianguppic" @click="addtouxiang()" v-else>
          <img src="../../assets/images/common/touxiang-grey.png" />
        </div>
        <div class="dianup" @click="addtouxiang()">点击上传</div>
      </div>

      <el-form
        :model="infoForm"
        ref="infoForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="姓名："
          prop="displayName"
          :rules="[{ required: true, message: '请输入您的姓名' }]"
        >
          <el-input
            v-model="infoForm.displayName"
            placeholder="请输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="性别："
          prop="sex"
          :rules="[{ required: true, message: '请确认您的性别' }]"
        >
          <div class="sex font14">
            <span
              :class="infoForm.sex == 1 ? 'active' : ''"
              @click="infoForm.sex = 1"
              >男</span
            >
            <span
              :class="infoForm.sex == 0 ? 'active' : ''"
              @click="infoForm.sex = 0"
              >女</span
            >
          </div>
        </el-form-item>
        <el-form-item
          label="出生年月："
          prop="birthday"
          :rules="emailrules.birthday"
        >
          <el-date-picker
            v-model="infoForm.birthday"
            type="date"
            placeholder="选择出生年月"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            v-model="phone"
            placeholder="请输入您的手机号码"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱：" prop="email" :rules="emailrules.email">
          <el-input
            v-model="infoForm.email"
            placeholder="请输入电子邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="wxNumber" :rules="[{ required: true, message: '请输入您的微信号' }]">
          <el-input
            v-model="infoForm.wxNumber"
            placeholder="请输入您的微信号"
          ></el-input>
        </el-form-item>
        <div class="beizhu font14">
          填写微信号能让您与企业握手后更好的沟通
        </div>
        <!-- @click="submitForm('infoForm')" -->
        <el-form-item
          ><el-button type="primary" @click="succeseitem('infoForm')"
            >确定</el-button
          ></el-form-item
        >
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";

export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    return {
      emailrules: {
        email: [
          { validator: checkEmail, required: true, trigger: "blur" },
          { required: true, message: "请输入电子邮箱" },
        ],
        birthday: [
          { validator: this.checkAge, required: true, trigger: "blur" },
          { required: true, message: '请选择出生年月' },
        ],
      },
      infoForm: {
        avatar: "",
        displayName: "",
        sex: "",
        birthday: "",
        email: "",
        wxNumber: "",
      },
      sexzhi: "nan",
      phone: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    checkAge(rule, value, callback){
      // console.log("checkAge--------rule = ", rule, "value = ", value);
      if (!value) {
        return callback(new Error("请确认您的生日不为空！"));
      }
      setTimeout(() => {
        let birthdays = new Date(value);
        let d = new Date();
        let age =
            d.getFullYear() -
            birthdays.getFullYear() -
            (d.getMonth() < birthdays.getMonth() ||
            (d.getMonth() == birthdays.getMonth() && d.getDate() < birthdays.getDate())
                ? 1 : 0);
        // console.log("checkAge--------birthdays = ", birthdays, "， age = ", age);
        if(age < 18){
          callback(new Error("您输入的年龄未满18岁，请确认后重新输入！"));
        }else {
          callback();
        }
      }, 100);
    },
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        for (let k in this.infoForm) {
          this.infoForm[k] = res.data.user[k];
          this.phone = res.data.user.phone;
        }
      });
    },
    succeseitem(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = JSON.parse(JSON.stringify(this.infoForm));
          let time = new Date(config.birthday);
          let y = time.getFullYear();
          let m = time.getMonth() + 1;
          let d = time.getDate();
          config.birthday = y + "-" + m + "-" + d;
          this.$api.updateUserInfo("post", config).then(() => {
            Bus.$emit("zcsuccsestuts", true);
          });
        } else {
          return false;
        }
      });
    },
    addtouxiang() {
      Bus.$emit("modifyavatarstuts", {
        show: true,
        callback: (url) => {
          this.infoForm.avatar = url;
        },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("操作失败!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0 160px 0;
  margin-top: 20px;
  margin-bottom: 150px;
  border-radius: 0;

  .touxiangup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .touxianguppic {
      width: 92px;
      height: 92px;
      color: #111111;
      background-color: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      img {
        width: 42px;
        height: 48px;
      }
    }
    .dianup {
      margin-top: 24px;
    }
  }
  .demo-ruleForm {
    margin-top: 50px;
    .beizhu {
      color: rgb(102 102 102 / 45%);
      //padding: 0 15px;
      margin-left: 100px;
    }
    .sex {
      span {
        padding: 10px 45px;
        color: #999999;
        border: 1px solid #dddddd;
        margin-right: 20px;
      }
      .active {
        color: #04bdff;
        border: 1px solid #04bdff;
        background-color: #effbff;
      }
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
      width: 500px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 500px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
  }
}
</style>
